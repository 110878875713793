// Flip a leading icon to be a trailing icon
.send-email-btn {
    > span {
        flex-direction: row-reverse;
        gap: 0.5rem;
        > span {
            margin: 0;
        }
    }
}
